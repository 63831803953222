import AuthorProfilePicture from './AuthorProfilePicture';

import { AuthorRecord } from '@/models/author/Author';
import { getString } from '@/content/i18n';
import { heapAuthorRecommendationsCoAuthorsButton } from '@/analytics/attributes/authorPage';
import { pluralize } from '@/util';
import CLButton, { TYPE } from '@/components/library/button/CLButton';
import CLCardContainer from '@/components/library/container/CLCardContainer';
import FeatureDisabled from '@/components/util/features/FeatureDisabled';
import FeatureEnabled from '@/components/util/features/FeatureEnabled';
import FeatureGate from '@/components/util/features/FeatureGate';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';

import classnames from 'classnames';
import Immutable from 'immutable';
import React from 'react';

import type { AuthorRecommendationRecord } from '@/models/author/AuthorRecommendation';
import type { Nullable, ReactNodeish } from '@/utils/types';

type Props = {
  maxResults?: Nullable<number>;
  recommendations: Immutable.List<AuthorRecommendationRecord>;
  author: Nullable<AuthorRecord> | undefined;
};

export default class AuthorRecommendations extends React.PureComponent<Props> {
  renderRecommendationRow(rec: AuthorRecommendationRecord): ReactNodeish {
    const { author, isCoauthor } = rec;
    return (
      // navigate to 404 page if missing authorId and slug
      <Link
        key={author.id}
        data-heap-id="author-recs-link"
        to={author.slug && author.id ? 'AUTHOR_PROFILE' : 'NOT_FOUND'}
        enableVisitedStyle={true}
        params={author.slug && author.id ? { slug: author.slug, authorId: author.id } : {}}>
        <CLCardContainer key={author.id && author.id.toString()}>
          <FeatureGate feature={_ => _.AuthorProfilePicture}>
            <FeatureEnabled>
              <div className="author-recs__author-profile-picture">
                <AuthorProfilePicture author={author} sizePx={38} />
              </div>
            </FeatureEnabled>
            <FeatureDisabled>
              <div className="author-recs__author-icon">
                <Icon icon="user" width="19" height="19" />
              </div>
            </FeatureDisabled>
          </FeatureGate>
          <ul>
            <li>
              <span className="author-recs__author-title">{author.name}</span>
            </li>
            <li className="author-recs__metadata">
              {getString(
                _ => _.author.recommendations.counts.formattedCounts,
                pluralize(
                  author.statistics.totalPaperCount || 0,
                  getString(_ => _.author.recommendations.counts.publication)
                ),
                pluralize(
                  author.statistics.totalCitationCount || 0,
                  getString(_ => _.author.recommendations.counts.citation)
                )
              )}
            </li>
            {isCoauthor && (
              <li className="author-recs__metadata">
                {getString(_ => _.author.recommendations.coAuthorLabel)}
              </li>
            )}
          </ul>
        </CLCardContainer>
      </Link>
    );
  }

  render(): ReactNodeish {
    const { recommendations, maxResults, author } = this.props;
    const recsToDisplay = maxResults ? recommendations.slice(0, maxResults) : recommendations;
    const authorId = author?.id || null;
    const slug = author?.slug || null;

    if (recsToDisplay.isEmpty()) {
      return null;
    }
    return (
      <div
        aria-label={getString(_ => _.author.recommendations.headline)}
        aria-describedby="claim-author-page-button-tooltip"
        tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
        className={classnames('author-recs', {
          'author-recs__compact': !resultsHaveCoAuthors(recsToDisplay),
        })}>
        <div className="author-recs__top-flex">
          <h2 className="author-recs__header">
            {getString(_ => _.author.recommendations.headlineCoAuthors)}
          </h2>
        </div>
        {recsToDisplay.map(rec => this.renderRecommendationRow(rec))}
        {authorId && slug && (
          <Link
            to="AUTHOR_PROFILE_CO_AUTHORS"
            params={{ slug, authorId }}
            aria-label={getString(_ => _.author.recommendations.viewAllCoAuthors)}
            {...heapAuthorRecommendationsCoAuthorsButton()}>
            <CLButton
              label={getString(_ => _.author.recommendations.viewAllCoAuthors)}
              type={TYPE.SECONDARY}
              className="author-recs__coauthors-button"
            />
          </Link>
        )}
      </div>
    );
  }
}

export const resultsHaveCoAuthors = (recs: Immutable.List<AuthorRecommendationRecord>): boolean => {
  return !!recs.find(r => r.isCoauthor);
};
