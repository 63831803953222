import { getString } from '@/content/i18n';
import {
  heapAuthorCitingAuthorsTab,
  heapAuthorCoAuthorsTab,
  heapAuthorPublicationsTab,
  heapAuthorReferencedAuthorsTab,
} from '@/analytics/attributes/authorPage';
import { Nullable } from '@/utils/types';
import AppContext, { mapAppContextToProps, useStateFromStore } from '@/AppContext';
import NavLink from '@/router/NavLink';

import classnames from 'classnames';
import React from 'react';

type PropsFromAppContext = {
  authorId: Nullable<string>;
  slug: Nullable<string>;
  numPublications: Nullable<number>;
  numCitingAuthors: Nullable<number>;
  numReferencedAuthors: Nullable<number>;
  numCoAuthors: Nullable<number>;
};

type Props = {} & PropsFromAppContext;

// eslint-disable-next-line max-lines-per-function
export function AuthorPageTabs({
  authorId,
  slug,
  numPublications,
  numCitingAuthors,
  numReferencedAuthors,
  numCoAuthors,
}: Props): React.ReactElement | null {
  if (!authorId || !slug) {
    return null;
  }

  return (
    <div className="author-page-tabs" data-test-id="author-page-tabs">
      <nav className="author-page-tabs__tab-group">
        <NavLink
          key="publications"
          data-test-id="author-page-tabs__publications"
          to="AUTHOR_PROFILE_PAPERS"
          params={{ slug, authorId }}
          className={classnames({
            'author-page-tabs__tab': true,
            'author-page-tabs__tab--is-empty': numPublications === 0,
          })}
          activeClassName="author-page-tabs__tab--is-active"
          shouldUnderline={false}
          exact={true}
          strict={true}
          {...heapAuthorPublicationsTab()}>
          <div className="author-page-tabs__tab__label">{getString(_ => _.author.tabs.papers)}</div>
        </NavLink>
        <NavLink
          key="citing-authors"
          data-test-id="author-page-tabs__citing-authors"
          to="AUTHOR_PROFILE_CITING_AUTHORS"
          params={{ slug, authorId }}
          className={classnames({
            'author-page-tabs__tab': true,
            'author-page-tabs__tab--is-empty': numCitingAuthors === 0,
          })}
          activeClassName="author-page-tabs__tab--is-active"
          shouldUnderline={false}
          exact={true}
          strict={true}
          {...heapAuthorCitingAuthorsTab()}>
          <div className="author-page-tabs__tab__label">
            {getString(_ => _.author.tabs.citingAuthors)}
          </div>
        </NavLink>
        <NavLink
          key="referenced-authors"
          data-test-id="author-page-tabs__referenced-authors"
          to="AUTHOR_PROFILE_REFERENCED_AUTHORS"
          params={{ slug, authorId }}
          className={classnames({
            'author-page-tabs__tab': true,
            'author-page-tabs__tab--is-empty': numReferencedAuthors === 0,
          })}
          activeClassName="author-page-tabs__tab--is-active"
          shouldUnderline={false}
          exact={true}
          strict={true}
          {...heapAuthorReferencedAuthorsTab()}>
          <div className="author-page-tabs__tab__label">
            {getString(_ => _.author.tabs.referencedAuthors)}
          </div>
        </NavLink>
        <NavLink
          key="co-authors"
          data-test-id="author-page-tabs__co-authors"
          to="AUTHOR_PROFILE_CO_AUTHORS"
          params={{ slug, authorId }}
          className={classnames({
            'author-page-tabs__tab': true,
            'author-page-tabs__tab--is-empty': numCoAuthors === 0,
          })}
          activeClassName="author-page-tabs__tab--is-active"
          shouldUnderline={false}
          exact={true}
          strict={true}
          {...heapAuthorCoAuthorsTab()}>
          <div className="author-page-tabs__tab__label">
            {getString(_ => _.author.tabs.coAuthors)}
          </div>
        </NavLink>
      </nav>
    </div>
  );
}

export default mapAppContextToProps<Props, PropsFromAppContext>(
  AuthorPageTabs,
  ({ authorStore, authorInfluenceStatsStore }: AppContext) => {
    const authorDetail = useStateFromStore(authorStore, _ => _.getAuthorDetails());
    const authorId = authorDetail?.author?.id || null;
    const influenceProps = useStateFromStore(authorInfluenceStatsStore, _ => ({
      numCitingAuthors: _.getNumCitingAuthors(),
      numCoAuthors: _.getNumCoAuthors(),
      numReferencedAuthors: _.getNumReferencedAuthors(),
      numPublications: _.getNumPublications(),
    }));

    return {
      authorId,
      slug: authorDetail?.author?.slug || null,
      ...influenceProps,
    };
  }
);
