import { MAX_NUM_AUTHOR_RECS } from './AuthorRoute';
import AuthorDetailCard from './AuthorDetailCard';
import AuthorPageTabs from './AuthorPageTabs';
import AuthorRecommendations from './AuthorRecommendations';

import { AuthorDetailsRecord } from '@/models/author/AuthorDetails';
import { AuthorRecommendationRecord } from '@/models/author/AuthorRecommendation';
import { getString } from '@/content/i18n';
import { mapAppContextToProps, useStateFromStore } from '@/AppContext';
import { Nullable, ReactNodeish } from '@/utils/types';
import ChildRoutes from '@/router/ChildRoutes';
import CLTwoColumnLayout, {
  CLLowerAside,
  CLMain,
  CLUpperAside,
} from '@/components/library/layout/CLTwoColumnLayout';
import EventTarget from '@/analytics/constants/EventTarget';
import Icon from '@/components/shared/icon/Icon';
import Page from '@/components/shared/layout/Page';
import ShowEvent from '@/analytics/models/ShowEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';
import withAnalyticsLocation from '@/components/shared/withAnalyticsLocation';

import Immutable from 'immutable';
import React from 'react';

type PropsFromAppContext = {
  isLoading: boolean;
  authorDetail?: Nullable<AuthorDetailsRecord>;
  authorRecommendations?: Nullable<Immutable.List<AuthorRecommendationRecord>>;
};

type Props = {} & PropsFromAppContext;

export function AuthorPage({
  isLoading,
  authorDetail,
  authorRecommendations,
}: Props): ReactNodeish {
  const isClaimedBySignedInUser = !!authorDetail?.isClaimedBySignedInUser;
  const showPendingCorrectionsMessage = !!(
    isClaimedBySignedInUser && authorDetail?.hasPendingCorrections
  );

  React.useEffect(() => {
    if (isClaimedBySignedInUser) {
      // We send an impression event for the edit button to indicate that a user is on their claimed author page
      trackAnalyticsEvent(
        ShowEvent.create(EventTarget.AuthorHomePage.IS_CLAIMED_BY_SIGNED_IN_USER_IMPRESSION)
      );
    }
  }, [isClaimedBySignedInUser]);

  return (
    <Page footer={!isLoading} className="author-page">
      {showPendingCorrectionsMessage && (
        <div className="author-page__corrections-pending">
          <Icon icon="status-waiting" />
          <div className="author-page__corrections-pending__label">
            {getString(_ => _.author.correctionPending.label)}
          </div>
        </div>
      )}
      <CLTwoColumnLayout className="author-page__layout">
        <CLUpperAside className="author-page__aside">
          <AuthorDetailCard showAHPExplanation />
        </CLUpperAside>
        <CLMain className="author-page__main">
          <AuthorPageTabs />
          <ChildRoutes />
        </CLMain>
        {authorRecommendations && authorRecommendations.size > 0 && (
          <CLLowerAside className="author-page__aside">
            <AuthorRecommendations
              recommendations={authorRecommendations}
              maxResults={MAX_NUM_AUTHOR_RECS}
              author={authorDetail?.author}
            />
          </CLLowerAside>
        )}
      </CLTwoColumnLayout>
    </Page>
  );
}

export default withAnalyticsLocation(EventTarget.AuthorHomePage.Publications)(
  mapAppContextToProps<Props, PropsFromAppContext>(AuthorPage, appContext => {
    const authorStoreProps = useStateFromStore(appContext.authorStore, _ => ({
      isLoading: _.isLoading(),
      authorDetail: _.getAuthorDetails(),
      authorRecommendations: _.getRecommendations(),
    }));

    return {
      ...authorStoreProps,
    };
  })
);
